export const TIME_OF_BIRTH = [
    {
        "title": "Tí (23g - 1g)",
        "value": 1
    },
    {
        "title": "Sửu (1g - 3g)",
        "value": 2
    },
    {
        "title": "Dần (3g - 5g)",
        "value": 3
    }, 
    {
        "title": "Mão (5g - 7g)",
        "value": 4
    },
    {
        "title": "Thìn (7g - 9g)",
        "value": 5
    },
    {
        "title": "Tị (9g - 11g)",
        "value": 6
    },
    {
        "title": "Ngọ (11g - 13g)",
        "value": 7
    },
    {
        "title": "Mùi (13g - 15g)",
        "value": 8
    },
    {
        "title": "Thân (15g - 17g)",
        "value": 9
    },
    {
        "title": "Dậu (17g - 19g)",
        "value": 10
    },
    {
        "title": "Tuất (19g - 21g)",
        "value": 11
    },
    {
        "title": "Hợi (21g - 23g)",
        "value": 12
    }
]