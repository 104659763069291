import React, { useState, useRef, useEffect } from "react";
import HoroscopeItem from "./HoroscopeItem"
import UserInfo from "./UserInfo"
import "./../styles/Horoscope.css"
import html2canvas from "html2canvas"

export default function Horoscope ({data}){
    const [selectedCung, setSelectedCung] = useState([])
    function dichCung(cungBanDau, soCungDich) {
        var cungSauKhiDich = Math.floor(cungBanDau);
        cungSauKhiDich += Math.floor(soCungDich);
        if (cungSauKhiDich % 12 == 0) {
            return 12;
        }
        else {
            return cungSauKhiDich % 12;
        }
    }
    function choose(cungid) {
        if (selectedCung.length && cungid === selectedCung[0]) {
            setSelectedCung([]);
            return
        }
        const cungXungChieu = dichCung(cungid, 6);
        const cungTamHop1 = dichCung(cungid, 4);
        const cungTamHop2 = dichCung(cungid, 8);
        setSelectedCung([cungid, cungXungChieu, cungTamHop1, cungTamHop2])
    }

    function download() {
        html2canvas(document.getElementById("horoscopes")).then(canvas => {
            var a = document.createElement('a');
            a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            const name = data.thienBan.ten ? data.thienBan.ten : "";
            a.download = `laso-${name}.jpg`;
            a.click();
        })
    }

    let scaleW = window.innerWidth > 1082 ? 1 : (window.innerWidth - 20) / 1082;
    let left = scaleW == 1 ? (window.innerWidth - 1082) / 2 : 10;
    let top = scaleW == 1 ? "0": "0"
    
    const horoscopeList = <div id="horoscopes" className="horoscope laso" style={{transform: `scale(${scaleW})`, left: `${left}px`, top}}>
        <HoroscopeItem key={"first"} cung={'first'} idx={6} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'ngo'} cung={'ngo'} idx={7} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'mui'} cung={'mui'} idx={8} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'than'} cung={'than'} idx={9} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'thil'} cung={'thil'} idx={5} selectedCung={selectedCung} value={data} choose={choose}/>
        <UserInfo value={data.thienBan} />
        <HoroscopeItem key={'dau'} cung={'dau'} idx={10} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'mao'} cung={'mao'} idx={4} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'tua'} cung={'tua'} idx={11} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'dan'} cung={'dan'} idx={3} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'suu'} cung={'suu'} idx={2} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'ti'} cung={'ti'} idx={1} selectedCung={selectedCung} value={data} choose={choose}/>
        <HoroscopeItem key={'hoi'} cung={'hoi'} idx={12} selectedCung={selectedCung} value={data} choose={choose}/>
    </div>;
    return Object.entries(data).length > 0 ?
        <div>
            {/* <button onClick={download} className={"mb-2"} >Lưu lá số</button> */}
            <div id="horoscopeList-result" style={{position: "relative"}}>{ horoscopeList }</div>
        </div>: <div></div>
}
