import React from "react";
import "./../styles/HoroscopeItem.css"

export default function HoroscopeItem(props) {
    const data = props.value.thapNhiCung.filter(item => item.cungSo == props.idx)[0]

    function getChinhTinh(){
        const cungSao = data.cungSao;
        const items = [];
        for (let item of cungSao) {
            if (item.saoLoai == 1)
        items.push(<li key={`chinh-tinh-${item.saoTen}`} className={`text-center ${item.cssSao}`}>{item.saoTen} {item.saoDacTinh ? `(${item.saoDacTinh})`: ""}</li>)
        }
        return <div className="chinhTinh mt-3">
            {items}
        </div>
    }

    function getSaoTot() {
        const cungSao = data.cungSao;
        const items = [];
        for (let item of cungSao) {
            if ( !item.vongTrangSinh && item.saoLoai !== 1 && item.saoLoai < 10) {
                items.push(<div className="grid" key={`sao-tot-${item.saoTen}`}>
                    <div className={`col ${item.cssSao} p-0`}>
                        {item.saoTen} {item.saoDacTinh ? `(${item.saoDacTinh})`: ""}
                    </div>
                </div>)
            }
        }
        return <div className="saotot text-left">
                {items}
            </div>
    }

    function getSaoXau(){
        const cungSao = data.cungSao;
        const items = [];
        for (let item of cungSao) {
            if (item.vongTrangSinh === 0 && item.saoLoai !==1 && item.saoLoai > 10) {
                items.push(<div className="grid" key={`sao-tot-${item.saoTen}`}>
                    <div className={item.cssSao}>
                        {item.saoTen} {item.saoDacTinh ? `(${item.saoDacTinh})`: ""}
                    </div>
                </div>)
            }
        }
        return <div className="saoxau">
            {items}
        </div>;
    }

    function getTuanTriet() {
        let stylePosition = "";
        switch (data.cungSo) {
            case 12:
            case 1:
            case 2:
                stylePosition = "bottom-left-cung";
                break;
            case 3:
            case 4:
            case 5:
                stylePosition = "top-center-cung";
                break;
            case 6:
            case 7:
            case 8:
                stylePosition = "bottom-right-cung";
                break;
            case 9:
            case 10:
            case 11:
                stylePosition = "bottom-center-cung";
                break;
        }
        const tuanTriet = [];
        if (data.tuanTrung) tuanTriet.push("Tuần")
        if (data.trietLo) tuanTriet.push("Triệt")
        return <div className={`tuanTriet ${stylePosition}`}>
            {tuanTriet.length ? <span className="label label-triet">{tuanTriet.join(" - ")}</span> : ""}
        </div>
    }

    function getSaoTen() {
        for (let item of data.cungSao) {
            if (item.vongTrangSinh === 1) return <div key={`sao-tot-${item.saoTen}`} style={{fontWeight: "bold"}} className={`col col-6 text-center`}>{changeSaoTen(item.saoTen)}</div>
        }
        return "";
    }

    function changeSaoTen(name){
        switch(name) {
            default:
                return name;
        }
    }

    function getClass() {
        const xungChieu = props.selectedCung.some(item => item == data.cungSo) ? "xungChieu" : "";
        return `horoscope-item diacung ${props.cung} ${xungChieu}`
    }

    function removeAccents(str) {
        var AccentsMap = [
          "aàảãáạăằẳẵắặâầẩẫấậ",
          "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
          "dđ", "DĐ",
          "eèẻẽéẹêềểễếệ",
          "EÈẺẼÉẸÊỀỂỄẾỆ",
          "iìỉĩíị",
          "IÌỈĨÍỊ",
          "oòỏõóọôồổỗốộơờởỡớợ",
          "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
          "uùủũúụưừửữứự",
          "UÙỦŨÚỤƯỪỬỮỨỰ",
          "yỳỷỹýỵ",
          "YỲỶỸÝỴ"
        ];
        for (var i=0; i<AccentsMap.length; i++) {
          var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
          var char = AccentsMap[i][0];
          str = str.replace(re, char);
        }
        return str.toLowerCase().split(" ").join("-");
      }



    return <div className={getClass()} onClick={() => props.choose(data.cungSo)}>
        <div className="grid cung-top">
            <div style={{whiteSpace: "nowrap"}} className={`col col-2 text-left p-0 ${data.cungTen}`} title="Địa chi cung">{data.cungTen}</div>
            <div className="col col-8 text-center">
                <span className={`cungChu text-center ${removeAccents(data.cungChu)}`}>
                    {data.cungChu}
                </span>
                {data.cungThan ? <span className="cungThan label">{"<Thân>"}</span> : ""}
            </div>
            <div className="col col-2 text-right" title="Đại hạn">{data.cungDaiHan}</div>
        </div>
        <div className="grid cung-middle">
            { getChinhTinh() }
            <div className="phuTinh d-flex">
                {getSaoTot()}
                { getSaoXau() }
            </div>
            { getTuanTriet() }
        </div>
        <div className="grid cung-bottom">
            <div className="col col-3 tooltips p-0 text-left" title="Tiểu hạn của năm">năm {data.cungTieuHan}</div>
            {getSaoTen()}
            <div className="col col-3 tooltips" title="Cung" style={{padding: 0}}>
                {data.cungAmDuong === -1 ? "-" : "+" }{data.hanhCung}
            </div>
        </div>
    </div>
}
