import React from "react";
import "../styles/UserInfo.css";


export default function UserInfo({value}) {
    return <div className="thienBan">
    <div className="noidung pt-4">
        <div className="text-center text-danger user-info-title" style={{fontSize: "40px", height: "90px"}}>
            <img src="https://tuvithienan.com/wp-content/themes/astrid/images/text-tuvi.png" />
        </div>
        <div className="website text-center">
            <a target="_blank" href="https://tuvithienan.com/">www.Tuvithienan.com</a>
        </div>
        <div className="hotline text-center">HOTLINE : 0388890008 – 0966326638</div>
        <div className="underline">
            <span>
            </span>
        </div>
        <div className="bg">
            <img style={{"opacity": "0.1"}} src="https://tuvithienan.com/wp-content/themes/astrid/images/logo-new.png" />
        </div>
        <div className="header">Ngày xem: {value.today}</div>
        <div className="grid">
            <div className="col col-3 cotTrai">Họ tên</div>
            <div className="col col-9 cotPhai">{value.ten}</div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Bát tự</div>
            <div className="col col-9 cotPhai">Năm {value.canNamTen} {value.chiNamTen}, tháng {value.canThangTen} {value.chiThangTen} {value.thangNhuan == 1 ? "(nhuận)" : ""}, ngày {value.canNgayTen} {value.chiNgayTen}, giờ {value.gioSinh}</div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Tuổi</div>
            <div className="col col-9 cotPhai">{value.amDuongNamSinh} {value.namNu} ({value.amDuongMenh})</div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Ngày sinh</div>
            <div className="col col-9 cotPhai">
                <div>{value.ngayAm}/{value.thangAm}/{value.canNamTen} {value.chiNamTen} (Âm lịch)</div>
                <div>{value.ngayDuong}/{value.thangDuong}/{value.namDuong} (Dương lịch)</div>
            </div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Bản mệnh</div>
            <div className="col col-9 cotPhai">
                {value.banMenh}
            </div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Cục</div>
            <div className="col col-9 cotPhai">
                {value.tenCuc}
            </div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Mệnh chủ</div>
            <div className="col col-9 cotPhai">
                {value.menhChu}
            </div>
        </div>

        <div className="grid">
            <div className="col col-3 cotTrai">Thân chủ</div>
            <div className="col col-9 cotPhai">
                {value.thanChu}
            </div>
        </div>

        <div className="grid sinhkhac">
            {value.sinhKhac}
        </div>

        <div className="mausac">
            <div className="grid">
                <span className="col col-2">Màu sắc</span>
                <span className="col col-2 hanhKim gioithieuhanh">Kim</span>
                <span className="col col-2 hanhThuy gioithieuhanh">Thủy</span>
                <span className="col col-2 hanhHoa gioithieuhanh">Hỏa</span>
                <span className="col col-2 hanhTho gioithieuhanh">Thổ</span>
                <span className="col col-2 hanhMoc gioithieuhanh">Mộc</span>
            </div>
        </div>
    </div>
    </div>
}
