import {Component} from "react"
import SearchInput from "../components/SearchInput"
import HoroscopeList from "../components/Horoscope"

export default class Horoscope extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: {}
        }
    }
    render() {
        return <div >
            <SearchInput getData={(data) => this.setState({data})} />
            <HoroscopeList data={this.state.data} />
        </div>
    }
}