import {Component, useState} from "react"
import "../styles/SearchInput.css"
import { TIME_OF_BIRTH } from "../constants/const"
import axios from "axios"

export default function SearchInput (props){
    const [username, setUsername] = useState("")
    const [sex, setSex] = useState("nam")
    const [dateOfBirth, setDateOfBirth] = useState(new Date().getDate())
    const [monthOfBirth, setMonthOfBirth] = useState(new Date().getMonth() + 1)
    const [yearOfBirth, setYearOfBirth] = useState(new Date().getFullYear())
    const [timeOfBirth, setTimeOfBirth] = useState(1)
    const [isLunarCalendar, setIsLunarCalender] = useState(false)
    const [timezone, setTimezone] = useState(7)
    const [namXem, setNamXem] = useState(new Date().getFullYear())

    function getSelectDate(){
        const options = [];
        for(let i = 1; i <= 31; i++) {
            options.push(<option key={`date-${i}`} value={i}>{i}</option>)
        }
        return <select value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} className="form-control mr-1">
            {options}
        </select>
    }

    function getSelectMonth(){
        const options = [];
        for(let i = 1; i <= 12; i++) {
            options.push(<option key={`month-${i}`} value={i}>Tháng {i}</option>)
        }
        return <select value={monthOfBirth} onChange={e => setMonthOfBirth(e.target.value)} className="form-control mr-1">
                {options}
            </select>
    }

    function getSelectYear (){
        const options = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear - 120; i <= currentYear; i++) {
            options.push(<option key={`year-${i}`} value={i}>Năm {i}</option>)
        }
        return <select value={yearOfBirth} onChange={e => setYearOfBirth(e.target.value)} className="form-control">
            {options}
        </select>
    }

    function getSelectNamXem(){
        const options = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear - 100; i <= currentYear + 100; i++) {
            options.push(<option key={`nam-${i}`} value={i}>Năm {i}</option>)
        }
        return <select value={namXem} onChange={e => setNamXem(e.target.value)} className="form-control">
            {options}
        </select>
    }

    function getSelectTimeOfBirth (){
        const options = [];
        for (let item of TIME_OF_BIRTH) {
            options.push(<option key={item.value} value={item.value}>{item.title}</option>)
        }
        return <select value={timeOfBirth} onChange={e => {console.log(e.target.value); setTimeOfBirth(e.target.value)}} className="form-control">
            {options}
        </select>
    }

    function getSelectTimezone () {
        const options = [];
        for (let i = -12; i <= 11; i++) {
            options.push(<option key={i}>{i}</option>)
        }
        return <select value={timezone} onChange={e => setTimezone(e.target.value)} className="form-control">
            {options}
        </select>
    }

    function submit(e) {
        e.preventDefault()
        axios.get("https://tuvithienan.com/lasotuvi/api", {
            params: {
                hoten: username,
                gioitinh: sex,
                ngaysinh: dateOfBirth,
                thangsinh: monthOfBirth,
                namsinh: yearOfBirth,
                giosinh: timeOfBirth,
                muigio: timezone,
                amlich: isLunarCalendar ? "on" : "off",
                namxem: namXem
            }
        }).then(result => {
            const data = changeData(result.data.thapNhiCung)
            props.getData({
                thapNhiCung: data,
                thienBan: result.data.thienBan
            })
        }).catch(err => {
            console.log(err)
        })

        // const data = {
        //     "thienBan": {
        //         "gioiTinh": 1,
        //         "namNu": "Nam",
        //         "chiGioSinh": {
        //             "id": 1,
        //             "tenChi": "T\u00fd",
        //             "tenHanh": "T",
        //             "menhChu": "Tham lang",
        //             "thanChu": "Linh tinh",
        //             "amDuong": 1
        //         },
        //         "canGioSinh": 9,
        //         "gioSinh": "Nh\u00e2m T\u00fd",
        //         "timeZone": 7,
        //         "today": "19/06/2021",
        //         "ngayDuong": 19,
        //         "thangDuong": 6,
        //         "namDuong": 2021,
        //         "ten": "",
        //         "ngayAm": 10,
        //         "thangAm": 5,
        //         "namAm": 2021,
        //         "thangNhuan": 0,
        //         "canThang": 1,
        //         "canNam": 8,
        //         "chiNam": 2,
        //         "chiThang": 5,
        //         "canThangTen": "Gi\u00e1p",
        //         "canNamTen": "T\u00e2n",
        //         "chiThangTen": "Ng\u1ecd",
        //         "chiNamTen": "S\u1eedu",
        //         "canNgay": 5,
        //         "chiNgay": 11,
        //         "canNgayTen": "M\u1eadu",
        //         "chiNgayTen": "Tu\u1ea5t",
        //         "amDuongNamSinh": "\u00c2m",
        //         "amDuongMenh": "\u00c2m d\u01b0\u01a1ng thu\u1eadn l\u00fd",
        //         "hanhCuc": 1,
        //         "tenCuc": "Kim t\u1ee9 C\u1ee5c",
        //         "menhChu": "C\u1ef1 m\u00f4n",
        //         "thanChu": "Thi\u00ean t\u01b0\u1edbng",
        //         "menh": "O",
        //         "sinhKhac": "B\u1ea3n M\u1ec7nh sinh C\u1ee5c",
        //         "banMenh": "B\u00cdCH TH\u01af\u1ee2NG TH\u1ed4"
        //     },
        //     "thapNhiCung": [
        //         {
        //             "cungSo": 0,
        //             "hanhCung": null,
        //             "cungSao": [],
        //             "cungAmDuong": -1,
        //             "cungTen": "Hem c\u00f3",
        //             "cungThan": false,
        //             "cungDaiHan": 74,
        //             "cungTieuHan": "T\u1ef5"
        //         },
        //         {
        //             "cungSo": 1,
        //             "hanhCung": "Th\u1ee7y",
        //             "cungSao": [
        //                 {
        //                     "saoID": 9,
        //                     "saoTen": "Tham Lang",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": "H"
        //                 },
        //                 {
        //                     "saoID": 36,
        //                     "saoTen": "\u0110\u1ea1i Hao",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 26,
        //                     "saoTen": "Tr\u1ef1c Ph\u00f9",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 16,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 44,
        //                     "saoTen": "Suy",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 106,
        //                     "saoTen": "LN V\u0103n Tinh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 81,
        //                     "saoTen": "Thi\u00ean Gi\u1ea3i",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": 1,
        //             "cungTen": "T\u00fd",
        //             "cungThan": false,
        //             "cungChu": "Thi\u00ean di",
        //             "cungDaiHan": 64,
        //             "cungTieuHan": "Ng\u1ecd"
        //         },
        //         {
        //             "cungSo": 2,
        //             "hanhCung": "Th\u1ed5",
        //             "cungSao": [
        //                 {
        //                     "saoID": 3,
        //                     "saoTen": "Thi\u00ean \u0110\u1ed3ng",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "H"
        //                 },
        //                 {
        //                     "saoID": 10,
        //                     "saoTen": "C\u1ef1 M\u00f4n",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "H"
        //                 },
        //                 {
        //                     "saoID": 35,
        //                     "saoTen": "B\u1ec7nh Ph\u00f9",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 15,
        //                     "saoTen": "Th\u00e1i Tu\u1ebf",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 15,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": 0,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 43,
        //                     "saoTen": "\u0110\u1ebf V\u01b0\u1ee3ng",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 73,
        //                     "saoTen": "Thi\u00ean H\u00ecnh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 15,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 89,
        //                     "saoTen": "Thi\u00ean S\u1ee9",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 105,
        //                     "saoTen": "Hoa C\u00e1i",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 14,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 99,
        //                     "saoTen": "Ph\u00e1 To\u00e1i",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 94,
        //                     "saoTen": "H\u00f3a L\u1ed9c",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 112,
        //                     "saoTen": "(L) Th\u00e1i Tu\u1ebf",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 112,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": 0,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": -1,
        //             "cungTen": "S\u1eedu",
        //             "cungThan": false,
        //             "cungChu": "T\u1eadt \u00c1ch",
        //             "cungDaiHan": 54,
        //             "cungTieuHan": "M\u00f9i"
        //         },
        //         {
        //             "cungSo": 3,
        //             "hanhCung": "M\u1ed9c",
        //             "cungSao": [
        //                 {
        //                     "saoID": 4,
        //                     "saoTen": "V\u0169 Kh\u00fac",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 11,
        //                     "saoTen": "Thi\u00ean T\u01b0\u1edbng",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "Nam \u0111\u1ea9u tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 34,
        //                     "saoTen": "H\u1ef7 Th\u1ea7n",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 16,
        //                     "saoTen": "Thi\u1ebfu D\u01b0\u01a1ng",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 108,
        //                     "saoTen": "Thi\u00ean Kh\u00f4ng",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 42,
        //                     "saoTen": "L\u00e2m Quan",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 7,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 60,
        //                     "saoTen": "Thi\u00ean Vi\u1ec7t",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 79,
        //                     "saoTen": "H\u1ed3ng Loan",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 8,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 96,
        //                     "saoTen": "C\u00f4 Th\u1ea7n",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 13,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 77,
        //                     "saoTen": "\u0110\u01b0\u1eddng Ph\u00f9",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 4,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 85,
        //                     "saoTen": "Phong C\u00e1o",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 4,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 104,
        //                     "saoTen": "Ki\u1ebfp S\u00e1t",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 115,
        //                     "saoTen": "(L) T.V",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": 1,
        //             "cungTen": "D\u1ea7n",
        //             "cungThan": false,
        //             "cungChu": "T\u00e0i B\u1ea1ch",
        //             "cungDaiHan": 44,
        //             "cungTieuHan": "Th\u00e2n"
        //         },
        //         {
        //             "cungSo": 4,
        //             "hanhCung": "M\u1ed9c",
        //             "cungSao": [
        //                 {
        //                     "saoID": 5,
        //                     "saoTen": "Th\u00e1i D\u01b0\u01a1ng",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "Nam \u0111\u1ea9u tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 12,
        //                     "saoTen": "Thi\u00ean L\u01b0\u01a1ng",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "Nam \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 33,
        //                     "saoTen": "Phi Li\u00eam",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 17,
        //                     "saoTen": "Tang M\u00f4n",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 41,
        //                     "saoTen": "Quan \u0110\u1edbi",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 4,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 56,
        //                     "saoTen": "H\u1ecfa Tinh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 93,
        //                     "saoTen": "H\u00f3a Quy\u1ec1n",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 4,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 117,
        //                     "saoTen": "(L) Tang M\u00f4n",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": -1,
        //             "cungTen": "M\u00e3o",
        //             "cungThan": false,
        //             "cungChu": "T\u1eed t\u1ee9c",
        //             "cungDaiHan": 34,
        //             "cungTieuHan": "D\u1eadu"
        //         },
        //         {
        //             "cungSo": 5,
        //             "hanhCung": "Th\u1ed5",
        //             "cungSao": [
        //                 {
        //                     "saoID": 13,
        //                     "saoTen": "Th\u1ea5t S\u00e1t",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "Nam \u0111\u1ea9u tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": "H"
        //                 },
        //                 {
        //                     "saoID": 32,
        //                     "saoTen": "T\u1ea5u Th\u01b0",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 18,
        //                     "saoTen": "Thi\u1ebfu \u00c2m",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 40,
        //                     "saoTen": "M\u1ed9c D\u1ee5c",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 14,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 58,
        //                     "saoTen": "V\u0103n Kh\u00fac",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "H"
        //                 },
        //                 {
        //                     "saoID": 90,
        //                     "saoTen": "Thi\u00ean La",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 92,
        //                     "saoTen": "H\u00f3a Khoa",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 102,
        //                     "saoTen": "L\u01b0u H\u00e0",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": 1,
        //             "cungTen": "Th\u00ecn",
        //             "cungThan": false,
        //             "cungChu": "Phu th\u00ea",
        //             "cungDaiHan": 24,
        //             "cungTieuHan": "Tu\u1ea5t",
        //             "tuanTrung": true,
        //             "trietLo": true
        //         },
        //         {
        //             "cungSo": 6,
        //             "hanhCung": "H\u1ecfa",
        //             "cungSao": [
        //                 {
        //                     "saoID": 6,
        //                     "saoTen": "Thi\u00ean C\u01a1",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "Nam \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 31,
        //                     "saoTen": "T\u01b0\u1edbng Qu\u00e2n",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 4,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 19,
        //                     "saoTen": "Quan Ph\u00f9",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 39,
        //                     "saoTen": "Tr\u00e0ng Sinh",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 63,
        //                     "saoTen": "Long Tr\u00ec",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 65,
        //                     "saoTen": "Tam Thai",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 7,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 69,
        //                     "saoTen": "Thi\u00ean Kh\u1ed1c",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 101,
        //                     "saoTen": "Thi\u00ean Ph\u00fac",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 74,
        //                     "saoTen": "Thi\u00ean Ri\u00eau",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 13,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "\u0110"
        //                 },
        //                 {
        //                     "saoID": 75,
        //                     "saoTen": "Thi\u00ean Y",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 76,
        //                     "saoTen": "Qu\u1ed1c \u1ea4n",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 118,
        //                     "saoTen": "(L) Thi\u00ean Kh\u1ed1c",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": -1,
        //             "cungTen": "T\u1ef5",
        //             "cungThan": false,
        //             "cungChu": "Huynh \u0111\u1ec7",
        //             "cungDaiHan": 14,
        //             "cungTieuHan": "H\u1ee3i",
        //             "tuanTrung": true,
        //             "trietLo": true
        //         },
        //         {
        //             "cungSo": 7,
        //             "hanhCung": "H\u1ecfa",
        //             "cungSao": [
        //                 {
        //                     "saoID": 1,
        //                     "saoTen": "T\u1eed vi",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "\u0110\u1ebf tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 30,
        //                     "saoTen": "Ti\u1ec3u Hao",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 20,
        //                     "saoTen": "T\u1eed Ph\u00f9",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 72,
        //                     "saoTen": "Nguy\u1ec7t \u0110\u1ee9c",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 50,
        //                     "saoTen": "D\u01b0\u1ee1ng",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 62,
        //                     "saoTen": "H\u1eefu B\u1eadt",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 67,
        //                     "saoTen": "\u00c2n Quang",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 59,
        //                     "saoTen": "Thi\u00ean Kh\u00f4i",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 84,
        //                     "saoTen": "Thai Ph\u1ee5",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 78,
        //                     "saoTen": "\u0110\u00e0o Hoa",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 8,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 103,
        //                     "saoTen": "Thi\u00ean Tr\u00f9",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 114,
        //                     "saoTen": "(L) T.K",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": 1,
        //             "cungTen": "Ng\u1ecd",
        //             "cungThan": true,
        //             "cungChu": "M\u1ec7nh",
        //             "cungDaiHan": 4,
        //             "cungTieuHan": "T\u00fd"
        //         },
        //         {
        //             "cungSo": 8,
        //             "hanhCung": "Th\u1ed5",
        //             "cungSao": [
        //                 {
        //                     "saoID": 29,
        //                     "saoTen": "Thanh Long",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 21,
        //                     "saoTen": "Tu\u1ebf Ph\u00e1",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 49,
        //                     "saoTen": "Thai",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 14,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 70,
        //                     "saoTen": "Thi\u00ean H\u01b0",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": "\u0110"
        //                 },
        //                 {
        //                     "saoID": 86,
        //                     "saoTen": "Thi\u00ean T\u00e0i",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 87,
        //                     "saoTen": "Thi\u00ean Th\u1ecd",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 119,
        //                     "saoTen": "(L) Thi\u00ean H\u01b0",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": -1,
        //             "cungTen": "M\u00f9i",
        //             "cungThan": false,
        //             "cungChu": "Ph\u1ee5 m\u1eabu",
        //             "cungDaiHan": 114,
        //             "cungTieuHan": "S\u1eedu"
        //         },
        //         {
        //             "cungSo": 9,
        //             "hanhCung": "Kim",
        //             "cungSao": [
        //                 {
        //                     "saoID": 14,
        //                     "saoTen": "Ph\u00e1 Qu\u00e2n",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "H"
        //                 },
        //                 {
        //                     "saoID": 28,
        //                     "saoTen": "L\u1ef1c S\u0129",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 22,
        //                     "saoTen": "Long \u0110\u1ee9c",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 48,
        //                     "saoTen": "Tuy\u1ec7t",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 51,
        //                     "saoTen": "\u0110\u00e0 La",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 61,
        //                     "saoTen": "T\u1ea3 Ph\u00f9",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 68,
        //                     "saoTen": "Thi\u00ean Qu\u00fd",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 80,
        //                     "saoTen": "Thi\u00ean H\u1ef7",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 110,
        //                     "saoTen": "(L) \u0110\u00e0 La",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 121,
        //                     "saoTen": "(L) M.D",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 14,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": 1,
        //             "cungTen": "Th\u00e2n",
        //             "cungThan": false,
        //             "cungChu": "Ph\u00fac \u0111\u1ee9c",
        //             "cungDaiHan": 104,
        //             "cungTieuHan": "D\u1ea7n"
        //         },
        //         {
        //             "cungSo": 10,
        //             "hanhCung": "Kim",
        //             "cungSao": [
        //                 {
        //                     "saoID": 27,
        //                     "saoTen": "L\u1ed9c T\u1ed3n",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 109,
        //                     "saoTen": "B\u00e1c S\u1ef9",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 23,
        //                     "saoTen": "B\u1ea1ch H\u1ed5",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 47,
        //                     "saoTen": "M\u1ed9",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 2,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 64,
        //                     "saoTen": "Ph\u01b0\u1ee3ng C\u00e1c",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 83,
        //                     "saoTen": "Gi\u1ea3i Th\u1ea7n",
        //                     "saoNguHanh": "M",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhMoc",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 66,
        //                     "saoTen": "B\u00e1t T\u1ecda",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 7,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 100,
        //                     "saoTen": "Thi\u00ean Quan",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 107,
        //                     "saoTen": "\u0110\u1ea9u Qu\u00e2n",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 109,
        //                     "saoTen": "(L) L\u1ed9c T\u1ed3n",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 116,
        //                     "saoTen": "(L) B\u1ea1ch H\u1ed5",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 120,
        //                     "saoTen": "(L) T.S",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": -1,
        //             "cungTen": "D\u1eadu",
        //             "cungThan": false,
        //             "cungChu": "\u0110i\u1ec1n tr\u1ea1ch",
        //             "cungDaiHan": 94,
        //             "cungTieuHan": "M\u00e3o"
        //         },
        //         {
        //             "cungSo": 11,
        //             "hanhCung": "Th\u1ed5",
        //             "cungSao": [
        //                 {
        //                     "saoID": 2,
        //                     "saoTen": "Li\u00eam Trinh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 7,
        //                     "saoTen": "Thi\u00ean Ph\u1ee7",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "Nam \u0111\u1ea9u tinh",
        //                     "saoAmDuong": 1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": "V"
        //                 },
        //                 {
        //                     "saoID": 38,
        //                     "saoTen": "Quan Ph\u1ee7",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 24,
        //                     "saoTen": "Ph\u00fac \u0110\u1ee9c",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 71,
        //                     "saoTen": "Thi\u00ean \u0110\u1ee9c",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 46,
        //                     "saoTen": "T\u1eed",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 52,
        //                     "saoTen": "K\u00ecnh d\u01b0\u01a1ng",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 55,
        //                     "saoTen": "Linh Tinh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 57,
        //                     "saoTen": "V\u0103n X\u01b0\u01a1ng",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 6,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": "\u0110"
        //                 },
        //                 {
        //                     "saoID": 97,
        //                     "saoTen": "Qu\u1ea3 T\u00fa",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 13,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 91,
        //                     "saoTen": "\u0110\u1ecba V\u00f5ng",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 95,
        //                     "saoTen": "H\u00f3a K\u1ef5",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 13,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "\u0110"
        //                 },
        //                 {
        //                     "saoID": 111,
        //                     "saoTen": "(L) K\u00ecnh D\u01b0\u01a1ng",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": 1,
        //             "cungTen": "Tu\u1ea5t",
        //             "cungThan": false,
        //             "cungChu": "Quan l\u1ed9c",
        //             "cungDaiHan": 84,
        //             "cungTieuHan": "Th\u00ecn"
        //         },
        //         {
        //             "cungSo": 12,
        //             "hanhCung": "Th\u1ee7y",
        //             "cungSao": [
        //                 {
        //                     "saoID": 8,
        //                     "saoTen": "Th\u00e1i \u00c2m",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 1,
        //                     "saoPhuongVi": "B\u1eafc \u0111\u1ea9u tinh",
        //                     "saoAmDuong": -1,
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 37,
        //                     "saoTen": "Ph\u1ee5c Binh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 13,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 25,
        //                     "saoTen": "\u0110i\u1ebfu Kh\u00e1ch",
        //                     "saoNguHanh": "K",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhKim",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 45,
        //                     "saoTen": "B\u1ec7nh",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 1,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 54,
        //                     "saoTen": "\u0110\u1ecba Ki\u1ebfp",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 53,
        //                     "saoTen": "\u0110\u1ecba Kh\u00f4ng",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 11,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "M"
        //                 },
        //                 {
        //                     "saoID": 82,
        //                     "saoTen": "\u0110\u1ecba Gi\u1ea3i",
        //                     "saoNguHanh": "O",
        //                     "saoLoai": 5,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhTho",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 88,
        //                     "saoTen": "Thi\u00ean Th\u01b0\u01a1ng",
        //                     "saoNguHanh": "T",
        //                     "saoLoai": 12,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhThuy",
        //                     "saoDacTinh": null
        //                 },
        //                 {
        //                     "saoID": 98,
        //                     "saoTen": "Thi\u00ean M\u00e3",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": "\u0110"
        //                 },
        //                 {
        //                     "saoID": 113,
        //                     "saoTen": "(L) Thi\u00ean M\u00e3",
        //                     "saoNguHanh": "H",
        //                     "saoLoai": 3,
        //                     "saoPhuongVi": "",
        //                     "saoAmDuong": "",
        //                     "vongTrangSinh": 0,
        //                     "cssSao": "hanhHoa",
        //                     "saoDacTinh": null
        //                 }
        //             ],
        //             "cungAmDuong": -1,
        //             "cungTen": "H\u1ee3i",
        //             "cungThan": false,
        //             "cungChu": "N\u00f4 b\u1ed9c",
        //             "cungDaiHan": 74,
        //             "cungTieuHan": "T\u1ef5"
        //         }
        //     ]
        // }
        // props.getData({
        //     thapNhiCung: data.thapNhiCung,
        //     thienBan: data.thienBan
        // })
    }

    function changeData(data) {
        data = JSON.parse(JSON.stringify(data))
        for (let idx = 1; idx <= 12; idx++) {
            const idxNext = getIdxNext(idx);
            if (data[idx].trietLo && data[idxNext].trietLo) {
                data[idxNext].trietLo = false
            }
            if (data[idx].tuanTrung && data[idxNext].tuanTrung) {
                data[idxNext].tuanTrung = false
            }
        }
        return data
    }

    function getIdxNext(index) {
        if (index < 12) return index + 1;
        return 1;
    }

    return (
        <div className="search-input container mt-5 p-3 mb-5">
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Họ tên</label>
                    <div className="col-sm-5">
                        <input type="text"
                            className="form-control"
                            value={username}
                            placeholder="Nhập họ tên của bạn"
                            onChange = {e => setUsername(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Giới tính</label>
                    <div className="col-sm-5">
                        <select value = {sex} onChange = {e => setSex(e.target.value)} className="form-control">
                            <option value="nam">Nam</option>
                            <option value="nu">Nữ</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                        <label className="col-form-label col-sm-2">Ngày sinh dương lịch</label>
                        <div className="col-sm-5 d-flex">
                            {getSelectDate()}
                            {getSelectMonth()}
                            {getSelectYear()}
                        </div>
                        {/* <label className="col-form-label col-sm-2">Âm lịch</label>
                        <div className="col-sm-1 d-flex align-items-center">
                            <input checked = {isLunarCalendar}
                                    className="form-check-input mb-1 ml-1"
                                    type="checkbox"
                                    onChange={e => setIsLunarCalender(e.target.checked)}
                                />
                        </div> */}
                </div>
                <div className="form-group row">
                    <label className="col-form-label col-sm-2">Giờ sinh</label>
                    <div className="col-sm-5">
                        {getSelectTimeOfBirth()}
                    </div>
                    <label className="col-form-label col-sm-2">Múi giờ</label>
                    <div className="col-sm-2">
                        {getSelectTimezone()}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-form-label col-sm-2">Năm xem</label>
                    <div className="col-sm-5">
                        {getSelectNamXem()}
                    </div>
                </div>
                <div className="form-group row submit text-center ml-1" style={{display: "flex", alignItems: "center", justifyContent: "flex-end",}}>
                    <button style={{textTransform: "uppercase", marginRight: "93px"}} type="button" className="btn btn-primary" onClick={submit}>Xem tử vi</button>
                </div>
            </form>
        </div>
    )
}
